
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import before_login from "@/vuex/before_login";
import session from "@/vuex/session";
import dialog from "@/vuex/dialog";
import header from "@/vuex/header";
import node from "@/vuex/node";

@Component({
})
export default class Login extends Mixins(utilMixins) {
  created() {
    session.deleteSession();
  }

  back_login() {
    this.$router.push({name: 'login', params: {company_code: this.return_company_code}});//login画面に飛ぶ
  }

  get return_company_code() {
    let session_company_code = util.getSessionStorage('company_code');
    let local_company_code = util.getLocalStorage('company_code');
    if (session_company_code !== null) {
      return session_company_code;
    } else if (local_company_code !== null) {
      return local_company_code;
    } else {
      return null;
    }
  }
}
